import Vue from 'vue';
import Config from '@/config';

const mutations = {
    topicAssign: (state, payload) => {
        console.log("MUTATION topic/assign", payload);
        if (!payload?.topicAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.topicAuth;
        const userAuth = payload.assigneeAuth || payload.ownerAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property;
        if (typeof payload.ownerAuth !== "undefined") property = "ownerId";
        else property = "userId";

        // update item in a area
        const items = state.session.area.topics;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update item in context
        if (state.page.context.topic?.auth === auth) state.page.context.topic[property] = userId;

        // broadcast to subscribers of this
        if ( item ) {
            const broadcast = [{
                entity: "group",
                auth: item.groupAuth,
            }];
            payload.broadcast = broadcast;
            console.log("broadcast", broadcast);
        }
    },
    topicDelete: (state, payload) => {
        console.log("MUTATION topic/delete", payload);

        // delete from area
        const auth = payload.topicAuth;
        const topic = state.session.area.topics?.find(el=>el.auth === auth);
        if ( topic ) {
            const index = state.session.area.topics.findIndex(el => el.auth === auth);
            if (index >= 0) state.session.area.topics.splice(index, 1);

            // delete from parent group children
            const group = state.session.area.groups?.find(el => el.auth === topic.groupAuth);
            if (group?.children) {
                const index = group.children.findIndex(el => el.auth === auth);
                if (index >= 0) group.children.splice(index, 1);
            }
        }

        // clear context
        if ( state.page.context.topic?.auth === auth ) {
            state.page.context.topic = null;
            state.page.tasks.items = null;
        }

        // broadcast to subscribers of this
        if ( topic ) {
            const broadcast = [{
                entity: "group",
                auth: topic.groupAuth,
            }];
            payload.broadcast = broadcast;
            console.log("broadcast", broadcast);
        }
    },
};

export default mutations;
