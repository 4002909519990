import {TAB_ACTUAL} from "@/utils/todos";
import {TAB_MSGS} from "@/utils/msg";

export default {
    isInited: false,

    /**
     * Current selected context objects.
     */
    context: {
        group: null,
        topic: null,
        assignee: null,
        prio: false,
        search: null,
        tab: TAB_ACTUAL,
        usersOfGroup: null,
    },

    /**
     * Actual task list from current context.
     */
    tasks: {
        isInited: false,
        items: null,
        //msgs: null,
        //files: null,
    },

    /**
     * Actual task (edited one).
     */
    task: {
        isInited: false,
        isSealed: false,
        item: null,
        children: null,
        parents: null,
        files: null,
        //siblings: null,
        //totalActualItems: null,
    },

    msgs: {
        items: null,
        tabId: TAB_MSGS,
    },

    msg: {
        item: {
            text: null,
            action: null,
        },
        files: null,

    },
}