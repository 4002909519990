//const storage = sessionStorage;
const storage = localStorage;

export default {
    get(name) {
        let value = storage.getItem(name);
        if (value) return JSON.parse(value);
        return undefined;
    },

    getNumber(name) {
        let value = storage.getItem(name);
        if (value !== null) return Number(value);
        return undefined;
    },

    getString(name) {
        let value = storage.getItem(name);
        if (value !== null) return ""+value;
        return undefined;
    },

    set(name, value) {
        if ( typeof value === "object" ) storage.setItem(name, JSON.stringify(value));
        else storage.setItem(name, value);
    },

    clear(name) {
        storage.removeItem(name);
    }
};