
export default {
    isInited: false,

    user: null,
    workspace: null,

    online: {
        users: null,
    },

    /**
     * Current environment: workspaces / groups / topics / users
     */
    area: {
        workspaces: null,
        groups: null,
        topics: null,
        users: null,
    },
}