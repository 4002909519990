import {customAlphabet} from 'nanoid';
import config from "@/config";
import storage from "@/utils/storage";

export function obtainClientId() {
	let clientId = storage.getString(config.STORAGE_CLIENT_ID_TOKEN);
	if (!clientId || clientId?.length !== config.CLIENT_ID_LENGTH) {
		const nanoid = customAlphabet(config.CLIENT_ID_ALPHABET, config.CLIENT_ID_LENGTH);
		clientId = nanoid();
		storage.set(config.STORAGE_CLIENT_ID_TOKEN, clientId);
	}
	return clientId;
}

export function obtainWorkspaceAuth() {
	return storage.getString(config.STORAGE_WORKSPACE_AUTH);
}