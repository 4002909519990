import Vue from 'vue';
import Config from '@/config';

const mutations = {
    taskCreate: (state, payload) => {
        console.log("MUTATION task/create", payload);

        // after creation - call this
        payload.mutation = "taskAppend";

        // after creation - broadcast to subscribers of this
        const broadcast = [{
            entity: "group",
            auth: payload.groupAuth,
        }];
        if (payload.topicAuth) broadcast.push({
            entity: "topic",
            auth: payload.topicAuth,
        });
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    taskAppend: (state, payload) => {
        console.log("MUTATION task/append", payload);
        if (!payload?.task?.item) return;

        const item = payload?.task?.item;

        const groupId = item.groupId;
        const topicId = item.topicId;

        // is context the same?
        const group = state.page.context.group;
        const topic = state.page.context.topic;
        if ((topic && topicId === topic.id) || (group && groupId === groupId)) {
            // insert item into context
            let items = state.page.tasks.items;
            if (!items) {
                Vue.set(state.page.tasks, "items", []);
                items = state.page.tasks.items;
            }
            if (items.findIndex(el => el.auth === item.auth) === -1) {
                items.unshift(item);
            }
        }

        // update current item
        //if (state.page.task.item && state.page.task.item.auth === auth) update(state.page.task.item);
    },
    taskUpdate: (state, payload) => {
        console.log("MUTATION task/update", payload);
        if (!payload?.taskAuth) return;
        if (!payload?.params) return;

        const auth = payload.taskAuth;

        const update = (item) => {
            Object.keys(payload?.params).forEach(key => {
                Vue.set(item, key, payload?.params[key]);
            })
        }

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) update(item);
        }

        // update current item
        if (state.page.task.item?.auth === auth) {
            update(state.page.task.item);

            // apply msgs
            if ( payload.msgs?.items ) {
                state.page.msgs.items = payload.msgs.items;
            }
        }

        // after creation - broadcast to subscribers of this
        item = item || state.page.task.item;
        if (item) {
            const broadcast = [{
                entity: "task",
                auth: item.auth,
            }];
            //console.log("ITEM", item);
            const group = state.session.area.groups.find(el => el.id === item.groupId);
            if (group) {
                broadcast.push({
                    entity: "group",
                    auth: group.auth,
                });

                //console.log("GROUP", group);
                let topic = item.topicId ? state.session.area.topics.find(el => el.id === item.topicId) : null
                //console.log("TOPIC", topic);

                if (topic) broadcast.push({
                    entity: "topic",
                    auth: topic.auth,
                });
                payload.broadcast = broadcast;
                console.log("broadcast", broadcast);
            }
        }
    },
    taskDelete: (state, payload) => {
        console.log("MUTATION task/delete", payload);

        const auth = payload.taskAuth;
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            const index = items.findIndex(el => el.auth === auth);
            if (index >= 0) {
                items.splice(index, 1);
                item = items[index];
            }
        }

        // todo remove current edited task

        if (item) {
            //console.log("ITEM", item);
            const group = state.session.area.groups.find(el => el.id === item.groupId);
            if (group) {
                //console.log("GROUP", group);
                let topic = item.topicId ? state.session.area.topics.find(el => el.id === item.topicId) : null
                //console.log("TOPIC", topic);
                const broadcast = [{
                    entity: "group",
                    auth: group.auth,
                }];
                if (topic) broadcast.push({
                    entity: "topic",
                    auth: topic.auth,
                });
                payload.broadcast = broadcast;
                console.log("broadcast", broadcast);
            }
        }
    },
    taskAssign: (state, payload) => {
        console.log("MUTATION task/assign", payload);
        if (!payload?.taskAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.taskAuth;
        const userAuth = payload.assigneeAuth || payload.ownerAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property;
        if (typeof payload.ownerAuth !== "undefined") property = "ownerId";
        else property = "userId";

        // update item in a context
        const items = state.page.tasks.items;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update current item
        if (state.page.task.item && state.page.task.item.auth === auth) state.page.task.item[property] = userId;

        // broadcast to subscribers of this
        item = item || state.page.task.item;
        if (item) {
            //console.log("ITEM", item);
            const group = state.session.area.groups.find(el => el.id === item.groupId);
            if (group) {
                //console.log("GROUP", group);
                let topic = item.topicId ? state.session.area.topics.find(el => el.id === item.topicId) : null
                //console.log("TOPIC", topic);
                const broadcast = [{
                    entity: "group",
                    auth: group.auth,
                }];
                if (topic) broadcast.push({
                    entity: "topic",
                    auth: topic.auth,
                });
                payload.broadcast = broadcast;
                console.log("broadcast", broadcast);
            }
        }
    },
    taskTemplate: (state, payload) => {
        console.log("MUTATION task/template", payload);
        mutations.taskUpdate(state, payload);
    },
    /*taskMsgs: (state, payload) => {
        console.log("MUTATION task/msgs", payload);
        if (!payload?.msg?.item.todoId) return;

        // are we in needed task?
        const taskId = payload.msg.item.todoId;
        //console.log("taskId", taskId);
        //console.log("state.page.task.item.id", state.page.task.item?.id);
        if (state.page.task.item?.id === taskId) {
            state.page.msgs.items = payload.msgs.items;
        }
    },*/

};

export default mutations;
