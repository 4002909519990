export default [
    {
        path: '/',
        component: () => import('../components/layouts/layout-default'),
        children:[
            {
                path: '/',
                name: 'home',
                /*component: () => import('../components/task'),
                meta: {api: "task-single"},*/
                redirect: {
                    name: 'my'
                },
            },
            {
                path: '/',
                component: () => import('../components/group'),
                children:[
                    {
                        name: 'my',
                        path: '/my',
                        component: () => import('../components/group/group-content'),
                        meta: {action: "user/tasks", subscribe: ["user"]},
                        children: [
                            {
                                name: 'my-task',
                                path: '/my/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/tasks", subscribe: ["user"]},
                                        {action: "task/item", subscribe: ["task"]},
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        name: 'group',
                        path: '/g/:groupAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "group/item"},
                        children: [
                            {
                                name: 'group-task',
                                path: '/g/:groupAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "group/item", subscribe: ["group"]},
                                        {action: "task/item", subscribe: ["task"]},
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        name: 'topic',
                        path: '/c/:topicAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "topic/item"},
                        children: [
                            {
                                name: 'topic-task',
                                path: '/c/:topicAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "topic/item", subscribe: ["topic"]},
                                        {action: "task/item", subscribe: ["task"]},
                                    ],
                                }
                            },
                        ]
                    },
                    {
                        name: 'prio',
                        path: '/p',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "soon", params: {prio: true}},
                        children: [
                            {
                                name: 'prio-task-new',
                                path: '/p/new',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "task/item"}
                            },
                            {
                                name: 'prio-task',
                                path: '/p/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "task/item", params: {prio: true}}
                            },
                        ]
                    },
                    {
                        path: '/s',
                        name: 'search',
                        component: () => import('../components/group/group-content'),
                        meta: {action: "soon", query: {search:"s",tab: "tab"}},
                        children: [
                            {
                                name: 'search-task',
                                path: '/s/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {search:"s",tab: "tab"}}
                            },
                        ]
                    },
                    {
                        name: 'users',
                        path: '/a',
                        component: () => import('../components/group/group-users'),
                        props: true,
                        meta: {action: "soon"}
                    },
                    {
                        name: 'assignee',
                        path: '/a/:assigneeAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {action: "soon", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'assignee-task-new',
                                path: '/a/:assigneeAuth/new',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {api: "group-item", query: {tab: "tab"}}
                            },
                            {
                                name: 'assignee-task',
                                path: '/a/:assigneeAuth/:taskAuth',
                                component: () => import('../components/group/group-content-task'),
                                props: true,
                                meta: {
                                    actions: [
                                        {action: "user/tasks"},
                                        {action: "task/item"},
                                    ]
                                }
                            },
                        ]
                    },
                ]
            },
            {
                path: '/t/:taskAuth',
                name: 'task',
                component: () => import('../components/task'),
                props: true,
                meta: {api: "task-item"}
            },
            {
                path: '/w',
                name: 'workspaces',
                component: () => import('../components/workspace'),
                props: true,
                meta: {api: "workspace-items"}
            },
            {
                path: '/:auth',
                redirect: {
                    name: 'group'
                },
            },
            {
                path: '/1/:contextAuth/:taskAuth',
                name: '1task',
                component: () => import('../components/task'),
                props: true,
                meta: {api: "task-item"}
            },
        ]
    },

    {
        path: '/r/auth',
        name: 'auth',
        component: () => import('../components/auth'),
        meta: {}
    },
    {
        path: '/r/auth-as',
        name: 'auth-as',
        component: () => import('../components/auth-as'),
        meta: {
            skipSession: true,
        }
    },
    {
        path: '/error/404',
        name: 'error404',
        component: () => import('../components/exception'),
        meta: {code: '404'}
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/exception'),
        props: true,
    },
];
