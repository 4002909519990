const mutations = {
    uiToggleMobileGroups: (state, payload) => {
        state.ui.withMobileGroups = !state.ui.withMobileGroups;
    },
    uiSetWithMobileGroups: (state, payload) => {
        state.ui.withMobileGroups = payload;
    },
    uiExtendMsgEditor: (state, payload) => {
        state.ui.isMsgEditorExpanded = payload;
    },
    uiSetSearch: (state, payload) => {
        state.ui.search.value = payload;
    },
    uiSetPendingArea: (state, payload) => {
        state.ui.pending.area = payload;
    },
    uiSetPendingTab: (state, payload) => {
        state.ui.pending.tab = payload;
    },
    uiItemMenu: (state, payload) => {
        //console.log("ITEM MENU MUTATION!");

        if (!payload.menu) return;
        if (!payload.params) return;

        const menu = payload.menu;

        let timeout = 0;
        if (menu.isVisible) {
            menu.isVisible = false;
            timeout = 150;

            if (menu.item === payload.params.item) {
                setTimeout(() => {
                    menu.item = null;
                }, timeout);
                return;
            }
        }

        setTimeout(() => {
            menu.isVisible = true;
            //menu.callback = payload.params.callback;
            //menu.item = payload.params.item;

            menu.x = payload.params.event?.clientX;
            menu.y = payload.params.event?.clientY + 16;

            Object.keys(payload.params).forEach(key => menu[key] = payload.params[key]);

        }, timeout);
    },

    /**
     * This mutation is called BEFORE routing.
     * Clear needed ui element states here.
     * @param state
     * @param payload
     */
    uiClearPageBeforeRouting: (state, {to, from}) => {
        //Vue.set(state.page.tasks, "isInited", false);

        if (!(to.params?.topicAuth && from.params?.topicAuth && to.params?.topicAuth === from.params.topicAuth)
            && !(to.params?.groupAuth && from.params?.groupAuth && to.params?.groupAuth === from.params.groupAuth)) {
            //this.commit("uiClearTask");
            state.page.tasks.isInited = false;
        }

        if (to.params?.taskAuth && from.params?.taskAuth && to.params?.taskAuth !== from.params.taskAuth) {
            //this.commit("uiClearTask");
            state.page.task.isInited = false;
            state.ui.isMsgEditorExpanded = false;

            mutations.uiClearMsg(state);
        }
    },
    /**
     * This mutation is called AFTER routing.
     * Clear needed ui element states here.
     * @param state
     * @param payload
     */
    uiClearPageAfterRouting: (state, {to, from}) => {
        state.ui.withMobileGroups = false;
    },
    uiClearMsg: (state, payload) => {
        state.page.msg.item.id = null;
        state.page.msg.item.auth = null;
        state.page.msg.item.text = null;
        state.page.msg.item.action = null;
    },

    uiSetTab: (state, payload) => {
        state.page.context.tab = payload;
    },
    uiError: (state, payload) => {
        //console.log("ITEM MENU MUTATION!");

        const error = state.ui.error;

        error.isVisible = true;
        error.message = payload.message;
        error.code = payload.code;
    },
};

export default mutations;
