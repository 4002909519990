import Vue from "vue";
import storage from "@/utils/storage";
import {ws} from "@/store/api";
import Config from "@/config";

/**
 * WebSocket integration
 */
const mutations = {

    SOCKET_ONOPEN(state, event) {
        Vue.prototype.$socket = event.currentTarget;
        state.isWSConnected = true;
        console.log("WS connected: "+Config.API_SERVER_WS_URI);

        // send client auth hello
        return ws(Config.WS_ACTION_HELLO, {params: {mutation: null}});
    },
    SOCKET_ONCLOSE(state, event) {
        state.isWSConnected = false;
        console.log("WS disconnected");
    },
    SOCKET_ONERROR(state, event) {
        //console.error(state, event)
        console.warn("WS error: ", event);
    },
    SOCKET_ONMESSAGE(state, message) {
        //console.log("WS message: ", message);

        this.commit("setResponse", message);

        /*if ( message.mutation ) {
            // incoming push-message action from server
            console.log("WS PUSH MUTATION:", message);
            return;

            const moduleName = message.cmd;
            const initialParams = message.data?.message?.params;
            this.commit(moduleName+'/'+methodName);
            //store.state.refreshCounter++;
        }*/
    },
    SOCKET_RECONNECT(state, count) {
        //console.info(state, count)
        console.warn("WS reconnected (" + count + ")");
    },
    SOCKET_RECONNECT_ERROR(state) {
        //state.socket.reconnectError = true;
        console.warn("WS reconnection error");
    },
};

export default mutations;
